import React from "react";
import classNames from "classnames";
import Info from "@components/Info";
import Button from "@components/Button";
import "./InfoSection.styles.scss";

const InfoSection = ({
  title = "",
  description = "",
  category = "",
  hasBackground = false,
  button = "",
  buttonLink,
}) => {
  return (
    <div
      className={classNames("info-section", {
        "info-section--has-background": hasBackground,
      })}
    >
      <div className="info-section__inner container">
        <Info
          title={title}
          description={description}
          category={category}
          isVertical
        />
        {button && (
          <Button type="big">
            <a href={buttonLink}>{button}</a>
          </Button>
        )}
      </div>
    </div>
  );
};

export default InfoSection;
