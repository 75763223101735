import React from "react";
import { withPrefix } from "gatsby";
import Layout from "@layouts/Layout";

import HowTemplate from "./How";

const HowPage = ({ data: initialData }) => {
  const data = initialData?.markdownRemark?.frontmatter;
  const seo = {
    ...(data?.seo || {}),
    image: data?.seo?.image?.childImageSharp?.fluid?.src,
  };
  return (
    <Layout seo={seo}>
      <HowTemplate data={data} />
    </Layout>
  );
};

export default HowPage;

export const whyPageQuery = graphql`
  query HowPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "How" } }) {
      frontmatter {
        seo {
          title
          description
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        header {
          title
          description
          buttonText
          subTitle
          video {
            name
            publicURL
          }
        }
        sectionOne {
          category
          title
          description
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        otherSections {
          title
          description
          buttonText
          buttonLink
        }
        contact {
          category
          title
          description
        }
      }
    }
  }
`;
