import React from "react";
import Header from "@layouts/Header";
import DefaultSection from "@sections/DefaultSection";
import InfoVerticalSection from "@sections/InfoVerticalSection";
import Contact from "@sections/Contact";

import "./How.styles.scss";

const HowTemplate = ({ data }) => {
  const header = data?.header || {};
  const sectionOne = data?.sectionOne || {};
  const otherSections = data?.otherSections || [];
  const contact = data?.contact || {};

  return (
    <div className="how">
      <Header {...header} />
      <DefaultSection
        className="how__top-section"
        info={{
          ...sectionOne,
        }}
        hasBackground
        hasIcons={false}
        image={sectionOne?.image}
      />
      {otherSections?.map((item, key) => {
        return (
          <InfoVerticalSection
            {...item}
            button={item.buttonText}
            buttonLink={item.buttonLink}
            hasBackground={key % 2}
          />
        );
      })}
      <Contact
        info={{
          ...contact,
          isWhite: true,
        }}
      />
    </div>
  );
};

export default HowTemplate;
